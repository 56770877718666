// @file Helper functions for billing components.
import { LibraryMembershipTier } from '@@/enums'
import type { Billing, BillingPaid, LibraryBilling, LibraryBillingPaid } from '@@/types'
import type { Card } from '@padlet/arvo'

enum SwitchType {
  SwitchImmediately = 'switch_immediately',
  SwitchAtEndOfTerm = 'switch_at_end_of_term',
}

function normalizedPaymentSchedule(paymentSchedule: string): 'monthly' | 'annual' {
  return paymentSchedule === 'month' ? 'monthly' : 'annual'
}

function normalizedOppositePaymentSchedule(paymentSchedule: string): 'monthly' | 'annual' {
  return normalizedPaymentSchedule(paymentSchedule === 'month' ? 'year' : 'month')
}

function getCardInformationText(card: Partial<Card>): string {
  if (!card.type) return ''
  const cardType = card.type[0].toUpperCase() + card.type.slice(1)
  return card.last4 ? `${cardType} *${card.last4}` : cardType
}

function calculateLibraryPlanTotalPrice({ makersCount, price }: { makersCount: number; price: number }): number {
  return makersCount * price
}

function isPaidBilling(billing: Billing | null): billing is BillingPaid {
  return billing?.tier === 'silver' || billing?.tier === 'gold' || billing?.tier === 'platinum'
}

function isPaidLibraryBilling(libraryBilling: LibraryBilling | null): libraryBilling is LibraryBillingPaid {
  return libraryBilling?.tier === LibraryMembershipTier.Gold
}

export {
  SwitchType,
  calculateLibraryPlanTotalPrice,
  getCardInformationText,
  isPaidBilling,
  isPaidLibraryBilling,
  normalizedOppositePaymentSchedule,
  normalizedPaymentSchedule,
}
